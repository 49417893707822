export const translationsLanding = {
    es: {
      title: "Álbum para Eventos con Código QR",
      welcome: "Creá recuerdos inolvidables",
      paragraph1:
        "Cada foto captura un recuerdo. Con Snap Moments, podés conservar esos momentos especiales en tu álbum personal, transformándolos en memorias duraderas de manera sencilla.",
      paragraph2:
        "Mirá en tiempo real cómo tu álbum se llena con las fotos de tus invitados, creando un diario visual que captura cada instante de tu evento.",
      howItWorks: "¿Cómo Funciona?",
      steps: [
        "Escaneá el código QR: En el evento, usá tu teléfono para escanear el código QR. Esto te llevará directamente al álbum de la fiesta.",
        "Subí tus fotos: Podés subir todas las fotos que quieras. ¡No hay límite para compartir tus recuerdos!",
        "Disfrutá en tiempo real: A medida que se suban las fotos, vas a ver cómo se llena el álbum, creando una colección viva de los mejores momentos de tu evento.",
      ],
      benefitsTitle: "Beneficios",
      benefits: [
        "Privacidad total: Solo tus invitados pueden ver las fotos.",
        "¡Subí y comentá!: Acompañá tus fotos con un mensaje divertido.",
        "Descarga express: Bajá el álbum completo con un clic.",
        "Control parental: Total tranquilidad con nuestro filtro, que evita que se suban fotos inapropiadas.",
        "Proyectá las fotos: Reproducí la galería en un proyector con la función de reproducción automática.",
      ],
    },
    pt: {
      title: "Álbum para Eventos com Código QR",
      welcome: "Crie memórias inesquecíveis",
      paragraph1:
        "Cada foto captura uma lembrança. Com Snap Moments, você pode guardar esses momentos especiais no seu álbum pessoal, transformando-os em memórias duradouras de maneira simples.",
      paragraph2:
        "Veja em tempo real como seu álbum se enche com as fotos dos seus convidados, criando um diário visual que captura cada instante do seu evento.",
      howItWorks: "Como Funciona?",
      steps: [
        "Escaneie o código QR: No evento, use seu celular para escanear o código QR. Isso levará você diretamente ao álbum da festa.",
        "Envie suas fotos: Você pode enviar quantas fotos quiser. Não há limite para compartilhar suas memórias!",
        "Aproveite em tempo real: À medida que as fotos são enviadas, você verá como o álbum se preenche, criando uma coleção viva dos melhores momentos do seu evento.",
      ],
      benefitsTitle: "Benefícios",
      benefits: [
        "Privacidade total: Apenas seus convidados podem ver as fotos.",
        "Envie e comente: Acompanhe suas fotos com uma mensagem divertida.",
        "Download rápido: Baixe o álbum completo com um clique.",
        "Controle parental: Tranquilidade total com nosso filtro, que evita o envio de fotos inadequadas.",
        "Projete as fotos: Reproduza a galeria em um projetor com a função de reprodução automática.",
      ],
    },
    en: {
      title: "Event Album with QR Code",
      welcome: "Create unforgettable memories",
      paragraph1:
        "Each photo captures a memory. With Snap Moments, you can preserve those special moments in your personal album, turning them into lasting memories effortlessly.",
      paragraph2:
        "Watch in real time as your album fills up with photos from your guests, creating a visual diary that captures every moment of your event.",
      howItWorks: "How Does It Work?",
      steps: [
        "Scan the QR code: At the event, use your phone to scan the QR code. This will take you directly to the event's album.",
        "Upload your photos: You can upload as many photos as you want. There’s no limit to sharing your memories!",
        "Enjoy in real time: As photos are uploaded, watch your album come to life, creating a living collection of your event's best moments.",
      ],
      benefitsTitle: "Benefits",
      benefits: [
        "Total privacy: Only your guests can view the photos.",
        "Upload and comment: Add a fun message to accompany your photos.",
        "Quick download: Download the full album with just one click.",
        "Parental control: Complete peace of mind with our filter that prevents inappropriate photos from being uploaded.",
        "Project the photos: Display the gallery on a projector using the autoplay feature.",
      ],
    },
  };