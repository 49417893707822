import React, { useState } from "react";

// Simple Modal Component for Guestbook
const GuestBookModal = ({
    isGuestBookOpen,
    fetchMessages,
    onClose,
    onSubmit,
    eventId,
  }) => {
    const [guestBookMessage, setGuestBookMessage] = useState("");
    const [userName, setUserName] = useState(""); // New state for user name
    const maxCharacters = 200; // Maximum number of characters
    const [isSubmitting, setIsSubmitting] = useState(false); // State for submission status

    const translationsGuestBook = {
        pt: {
          title: "Livro de Mensagens",
          namePlaceholder: "Seu nome",
          messagePlaceholder: "Escreva suas melhores palavras aqui...",
          characterCount: "caracteres",
          submit: "Enviar Mensagem",
          sending: "Enviando...",
          close: "Fechar",
          alertNameMessage: "Por favor, preencha tanto seu nome quanto a mensagem.",
          alertMaxLength: "A mensagem não pode exceder {{max}} caracteres."
        },
        es: {
          title: "Libro de Mensajes",
          namePlaceholder: "Tu nombre",
          messagePlaceholder: "Escribí tus mejores palabras acá...",
          characterCount: "caracteres",
          submit: "Enviar Mensaje",
          sending: "Enviando...",
          close: "Cerrar",
          alertNameMessage: "Por favor, completá tanto tu nombre como el mensaje.",
          alertMaxLength: "El mensaje no puede exceder {{max}} caracteres."
        },
        en: {
          title: "Guest Book",
          namePlaceholder: "Your name",
          messagePlaceholder: "Write your best words here...",
          characterCount: "characters",
          submit: "Submit Message",
          sending: "Sending...",
          close: "Close",
          alertNameMessage: "Please fill out both your name and the message.",
          alertMaxLength: "The message cannot exceed {{max}} characters."
        }
      };
      
      const lang = navigator.language.split("-")[0]; // Get the language code (es or pt)

    //   const content = lang === "pt" ? translationsGuestBook.pt : translationsGuestBook.es;
    const content = translationsGuestBook[lang] 
    ? translationsGuestBook[lang] 
    : translationsGuestBook['en']; // Fallback to 'en'
    
    const isSubmitDisabled =
      !guestBookMessage || !userName || guestBookMessage.length > maxCharacters;
  
    if (!isGuestBookOpen) return null;
  
    const handleSubmit = async () => {
      // Check for validation issues before submitting
      if (!guestBookMessage || !userName) {
        alert((content.alertNameMessage));
        return;
      }
      if (guestBookMessage.length > maxCharacters) {
        // alert(`El mensaje no puede superar los ${maxCharacters} caracteres.`);
        alert(content.alertMaxLength.replace('{{max}}', maxCharacters));
        return;
      }
  
      // If validation passes, submit the form
      const messageData = {
        userName,
        guestBookMessage,
        timestamp: new Date().toLocaleString(),
        eventId,
      };
  
      setIsSubmitting(true); // Disable the button while submitting
  
      await onSubmit(messageData);
      await fetchMessages();
  
      // Clear the inputs and close the modal
      setGuestBookMessage("");
      setUserName("");
      onClose();
      setIsSubmitting(false); // Re-enable the button after submission
    };
  
    // Call reset state on close
    const handleClose = () => {
      setGuestBookMessage("");
      setUserName("");
      onClose();
    };
  
    return (
      <div className="guestbook-modal-overlay" onClick={handleClose}>
        <div
          className="guestbook-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <h2>{content.title}</h2>
  
          {/* Input for User Name */}
          <input
            type="text"
            placeholder={content.namePlaceholder}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="guestbook-name-input"
          />
  
          {/* Text Area for Message Input */}
          <textarea
            placeholder={content.messagePlaceholder}
            value={guestBookMessage}
            onChange={(e) => setGuestBookMessage(e.target.value)}
            className="guestbook-textarea"
          />
  
          {/* Character Count */}
          <div className="character-count">
            {guestBookMessage.length}/{maxCharacters} {content.characterCount}
          </div>
  
          {/* Submit Button */}
          <button
            className="guestbook-submit-button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? content.sending : content.submit}
          </button>
  
          {/* Show error if message exceeds max characters */}
          {guestBookMessage.length > maxCharacters && (
            <p style={{ color: "red" }}>
              {/* El mensaje no puede superar los {maxCharacters} caracteres. */}
              {content.alertMaxLength.replace('{{max}}', maxCharacters)}
            </p>
          )}
  
          {/* Close Button */}
          <button className="guestbook-close-button" onClick={handleClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    );
  };
  
export default GuestBookModal