import { CARTELES_CANVA, CARTELES_CANVA_2, INVITACION_CUADRADO, INVITACION_VERTICAL, POCKET_QR } from "./CanvaEndpoints";

export const templateContent = {
    es: {
      title: "Plantillas para Eventos - SnapMoments",
      sectionTitle: "Personalizá tus eventos con plantillas (templates) listas para imprimir",
      sectionDescription:
        "¡Con cada álbum, tenés acceso a plantillas de Canva como bonus para editar e imprimir!",
      modelsTitle: "Modelos de Plantillas",
      model1: {
        name: "Carteles",
        description: "Ideales para la entrada de tu ceremonia o evento.",
        links: [
          { label: "Cartel vertical 1", url: CARTELES_CANVA },
          { label: "Cartel vertical 2", url: CARTELES_CANVA_2 },
        ],
      },
      model2: {
        name: "Tarjetas",
        description: "Elegí entre varios diseños personalizados.",
        links: [
          { label: "Invitación mesa cuadrado", url: INVITACION_CUADRADO },
          { label: "Invitación mesa vertical", url: INVITACION_VERTICAL },
        ],
      },
      model3: {
        name: "Pocket QR",
        description: "Souvenir para que cada invitado se lleve el código QR de tu galería.",
        links: [{ label: "Pocket QR", url: POCKET_QR }],
      },
      funWaysTitle: "Formas divertidas de recopilar fotos usando Snap Moments",
      useCases: [
        {
          className: "slideshow",

          title: "Presentación de fotos",
          description:
            "Activá la función de presentación de fotos y disfrutá de cómo se proyectan automáticamente las imágenes de los invitados. ¡Es una manera espectacular de resaltar tu evento desde todos los ángulos!",
        },
        {
          className: "mc-dj",

          title: "¡Involucrá a tu DJ!",
          description:
            "¿Qué mejor manera de anunciar la recopilación de fotos que con tu MC/DJ? Pedile que presente tu álbum durante la bienvenida, ¡y los invitados empezarán a agregar sus mejores fotos en un abrir y cerrar de ojos!",
        },
        {
          className: "cabina",

          title: "¡Tu propia cabina de fotos!",
          description:
            "Designá un área para las fotos, reuní accesorios y exhibí tu cartel con el código QR. Los invitados escanean y suben esos increíbles momentos a tu galería de Snap Moments. ¡Divertidísimo!",
        },
      ],
    },
    pt: {
      title: "Modelos para Eventos - SnapMoments",
      sectionTitle: "Personalize seus eventos com modelos (templates) prontos para imprimir",
      sectionDescription:
        "Com cada álbum, você tem acesso a modelos do Canva como bônus para editar e imprimir!",
      modelsTitle: "Modelos de Templates",
      model1: {
        name: "Cartazes",
        description: "Ideais para a entrada da sua cerimônia ou evento.",
        links: [
          { label: "Cartaz vertical 1", url: CARTELES_CANVA },
          { label: "Cartaz vertical 2", url: CARTELES_CANVA_2 },
        ],
      },
      model2: {
        name: "Convites",
        description: "Escolha entre vários designs personalizados.",
        links: [
          { label: "Convite mesa quadrado", url: INVITACION_CUADRADO },
          { label: "Convite mesa vertical", url: INVITACION_VERTICAL },
        ],
      },
      model3: {
        name: "Pocket QR",
        description: "Souvenir para que cada convidado leve o código QR da sua galeria.",
        links: [{ label: "Pocket QR", url: POCKET_QR }],
      },
      funWaysTitle: "Formas divertidas de coletar fotos usando Snap Moments",
      useCases: [
        {
          className: "slideshow",

          title: "Apresentação de fotos",
          description:
            "Ative a função de apresentação de fotos e aproveite como as imagens dos convidados são projetadas automaticamente. É uma maneira incrível de destacar seu evento de todos os ângulos!",
        },
        {
          className: "mc-dj",

          title: "Envolva seu DJ!",
          description:
            "Qual a melhor maneira de anunciar a coleta de fotos do que com o seu MC/DJ? Peça para ele apresentar seu álbum durante a recepção, e os convidados começarão a adicionar suas melhores fotos em um piscar de olhos!",
        },
        {
          className: "cabina",

          title: "Sua própria cabine de fotos!",
          description:
            "Crie uma área para fotos, reúna acessórios e exiba seu cartaz com o código QR. Os convidados escaneiam e sobem esses momentos incríveis para sua galeria no Snap Moments. Divertido demais!",
        },
      ],
    },
    en: {
      title: "Event Templates - SnapMoments",
      sectionTitle: "Personalize your events with ready-to-print templates",
      sectionDescription:
        "With each album, you get access to Canva templates as a bonus to edit and print!",
      modelsTitle: "Template Models",
      model1: {
        name: "Posters",
        description: "Perfect for the entrance of your ceremony or event.",
        links: [
          { label: "Vertical poster 1", url: CARTELES_CANVA },
          { label: "Vertical poster 2", url: CARTELES_CANVA_2 },
        ],
      },
      model2: {
        name: "Cards",
        description: "Choose from a variety of personalized designs.",
        links: [
          { label: "Square table card", url: INVITACION_CUADRADO },
          { label: "Vertical table card", url: INVITACION_VERTICAL },
        ],
      },
      model3: {
        name: "Pocket QR",
        description: "A souvenir for each guest to take the QR code of your gallery.",
        links: [{ label: "Pocket QR", url: POCKET_QR }],
      },
      funWaysTitle: "Fun ways to collect photos using Snap Moments",
      useCases: [
        {
          className: "slideshow",
          title: "Photo Slideshow",
          description:
            "Activate the photo slideshow feature and enjoy how guests' images are automatically displayed. It’s a spectacular way to showcase your event from every angle!",
        },
        {
          className: "mc-dj",
          title: "Get Your DJ Involved!",
          description:
            "What better way to announce photo collection than with your MC/DJ? Ask them to introduce your album during the reception, and guests will start adding their best photos in no time!",
        },
        {
          className: "cabina",
          title: "Your Own Photo Booth!",
          description:
            "Designate an area for photos, gather some props, and display your poster with the QR code. Guests scan and upload those amazing moments to your Snap Moments gallery. Super fun!",
        },
      ],
    },
  };