import React from "react";
import { Link } from "react-router-dom";
import logoEs from "./assets/images/logoSnapOk.png";
import logoPt from "./assets/images/logoSnapPT-bg.png"; // Portuguese logo
import logoEn from "./assets/images/logoSnapEN-bg.png"; // English logo


import "./assets/styles/Preguntas.css";
import "./assets/styles/LandingPage.css";
import { Helmet } from "react-helmet";
import FooterInstitucional from "./Components/FooterInstitucional/FooterInstitucional";
import { faqContent } from "./translationsFAQ";


function Preguntas() {
  // Set language to Spanish or Portuguese based on navigator
  const lang = navigator.language.split("-")[0]; // Get the language code (es or pt)

  
  const content = faqContent[lang] 
  ? faqContent[lang] 
  : faqContent['en']; // Fallback to 'en'

  // const logo = lang === "pt" ? logoPt : logoEs;
   // Set logo based on the language
   let logo;
   if (lang === "pt") {
     logo = logoPt;
   } else if (lang === "es") {
     logo = logoEs;
   } else {
     logo = logoEn; // Default to English logo if no 'pt' or 'es'
   }

  // const content = lang === "pt" ? faqContent.pt : faqContent.es;

  return (
    <div className="PreguntasPage">
      <Helmet>
        <title>{content.title}</title>
      </Helmet>
      <header className="LandingPage-header">
        <Link to="/">
          <img src={logo} alt="Logo" className="LandingPage-logo" />
        </Link>
      </header>
      <div className="PreguntasPage-content">
        <h1>{content.title}</h1>

        {content.questions.map((faq, index) => (
          <section key={index} className="PreguntasPage-section">
            <h2>{faq.question}</h2>
            <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
          </section>
        ))}
      </div>
      <FooterInstitucional />
    </div>
  );
}

export default Preguntas;
