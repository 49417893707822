import React, { useEffect, useState } from "react";
import "./assets/styles/LandingPage.css";
import logoEs from "./assets/images/logoSnapOk.png";
import logoPt from "./assets/images/logoSnapPT-bg.png"; // Portuguese logo
import logoEn from "./assets/images/logoSnapEN-bg.png"; // English logo

import exampleImage from "./assets/images/home-page.jpg"; // Replace with actual image path
import { Helmet } from "react-helmet-async";
import FooterInstitucional from "./Components/FooterInstitucional/FooterInstitucional";
import { translationsLanding } from "./translationsLanding";


function LandingPage() {
  // const [language, setLanguage] = useState("es");

  // useEffect(() => {
  //   const userLang = navigator.language.split("-")[0];
  //   setLanguage(translationsLanding[userLang] ? userLang : "es"); // Default to "es"
  //   console.log("Navigator language:", userLang);
  // }, []);

  // const t = translationsLanding[language];

  const lang = navigator.language.split("-")[0]; // Get the language code (e.g., 'es', 'pt')

  let logo;
if (lang === "pt") {
  logo = logoPt;
} else if (lang === "es") {
  logo = logoEs;
} else {
  logo = logoEn; // Default to English logo if no 'pt' or 'es'
}
const t = translationsLanding[lang] 
  ? translationsLanding[lang] 
  : translationsLanding['en']; // Fallback to 'en'

  // const logo = lang === "pt" ? logoPt : logoEs;




  return (
    <div className="LandingPage">
      <Helmet>
        <title>{t.title}</title>
      </Helmet>
      <header className="LandingPage-header">
        <img src={logo} alt="Logo" className="LandingPage-logo" />
      </header>
      <div className="LandingPage-content">
        <h2 className="LandingPage-titles">{t.welcome}</h2>
        <section className="LandingPage-section">
          <div className="LandingPage-text">
            <p>{t.paragraph1}</p>
            <p>{t.paragraph2}</p>
          </div>
          <div className="LandingPage-image">
            <img src={exampleImage} alt="Representação Visual" />
          </div>
        </section>

        <h2 className="ComoFunciona-section LandingPage-titles">{t.howItWorks}</h2>

        <section className="LandingPage-section expanded-section">
          <ol>
            {t.steps.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
        </section>

        <h2 className="LandingPage-titles">{t.benefitsTitle}</h2>

        <section className="LandingPage-section expanded-section">
          <ol>
            {t.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ol>
        </section>
      </div>
      <FooterInstitucional />
    </div>
  );
}

export default LandingPage;
