// src/utils/fileUtils.js

import Compressor from 'compressorjs';

export const handleFileChange = (event, setFile) => {
  const handleFileTranlations = {
    pt: {
      maxPhotoCount: "Você só pode enviar até 5 fotos.",
      invalidFileType: "O arquivo não é uma imagem ou extensão válida.",
      photoExceedsSize: "A foto excede 5 MB após a compressão."
    },
    es: {
      maxPhotoCount: "Solo podés subir hasta 5 fotos.",
      invalidFileType: "El archivo no es una imagen o extensión válida.",
      photoExceedsSize: "La foto supera los 5 MB después de la compresión."
    },
    en: {
      maxPhotoCount: "You can only upload up to 5 photos.",
      invalidFileType: "The file is not a valid image or extension.",
      photoExceedsSize: "The photo exceeds 5 MB after compression."
    },
  };

  const lang = navigator.language.split("-")[0]; // Get the language code (e.g., 'es', 'pt')

  const content = handleFileTranlations[lang]
    ? handleFileTranlations[lang]
    : handleFileTranlations["en"]; // Fallback to 'en'
  
  const selectedFiles = Array.from(event.target.files);

  if (selectedFiles.length > 5) {
    alert(content.maxPhotoCount);
    event.target.value = ''; // Clear the input to reset the file selection

    return;
  }

  // Filter valid files (without size validation)
  const validFiles = selectedFiles.filter((file) => {
    if (!file.type.startsWith("image/")) {
      alert(content.invalidFileType);
      // alert(`El archivo ${file.name} no es una imagen o extensión válida.`);
      return false;
    }
    return true;
  });

  if (validFiles.length > 0) {
    // Compress the images
    const compressedFiles = validFiles.map((file) => {
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.6, // Adjust the quality as needed
          success(result) {
            // Check if the compressed file size is within the limit
            if (result.size > 5 * 1024 * 1024) {
              alert(content.photoExceedsSize);
              // alert(`La foto ${result.name} supera los 5 MB después de la compresión.`);
              resolve(null); // Return null or an appropriate value for rejected files
            } else {
              // console.log(`Compressed size of ${file.name}: ${(result.size / 1024 / 1024).toFixed(2)} MB`);
              resolve(result);
            }
          },
          error(err) {
            console.error("Compression error:", err);
            resolve(null); // Resolve with null in case of error
          }
        });
      });
    });

    Promise.all(compressedFiles).then((files) => {
      // Filter out null values (rejected files)
      const finalFiles = files.filter(file => file !== null);
      setFile(finalFiles);
    });
  }
};