export const translationsAlbumPage = {
  pt: {
    //input name send photo form
    helmetTitle:"Álbum para Eventos com Código QR",
    inputNameSendPhoto: "Seu nome (obrigatório)",

    
    guestBookSentMessageError:
      "Houve um erro ao enviar sua mensagem. Tente novamente.",
    title: "Álbum",
    uploaderName: "Nome do carregador",
    albumOf: "Álbum de",
    coverImageUrl: "URL da imagem de capa",
    customMessage: "Mensagem personalizada",
    photoMessage: "Mensagem da foto",
    errorMessage: "Erro ao carregar o álbum. Tente novamente.",
    slideshowActive: "Apresentação de slides ativa",
    nextPhoto: "Próxima foto",
    prevPhoto: "Foto anterior",
    guestBookActive: "Livro de mensagens ativo",
    openGuestBook: "Abrir livro de mensagens",
    submitMessage: "Enviar mensagem",
    guestBookMessage: "Mensagem no livro de mensagens",
    loadingEventDetails: "Carregando detalhes do evento...",
    pageTitle: "Página do Álbum",
    bannerClosed: "O banner foi fechado.",
    openModal: "Abrir modal",
    closeModal: "Fechar modal",
    userIdGenerated: "Novo ID de usuário gerado",
    fetchingMessages: "Buscando mensagens...",
    noMessagesFound: "Nenhuma mensagem encontrada.",
    errorFetchingMessages: "Erro ao buscar mensagens.",
    photoDownloadSuccess: "Foto baixada com sucesso!",
    photoDownloadError: "Erro ao baixar a foto.",
    fetchingEventDetails: "Buscando detalhes do evento...",
    eventNotFound: "Evento não encontrado.",
    likeSuccess: "Curtida realizada com sucesso!",

    likeError: "Erro ao curtir a foto.",
    likeHeart: "curtir",

    secondaryThemeApplied: "Tema secundário aplicado.",
    secondaryThemeRemoved: "Tema secundário removido.",
    handleDeleteConfirmation:
      "Tem certeza de que deseja excluir esta mensagem?",
    deleteMessageError: "Erro ao excluir a mensagem:",
    fetchingPhotosError: "Erro ao obter as fotos:",
    photoUploadError: "Erro ao enviar o arquivo:",
    uploadInProgress: "Envio em andamento, por favor, aguarde...",
    noFileSelected: "Por favor, selecione pelo menos um arquivo para enviar.",
    noNameEntered: "Por favor, insira seu nome antes de enviar a foto.",
    safeSearchEvaluationMessage:
      "A foto está sendo avaliada devido a possíveis conteúdos impróprios.",
    photoDeletedSuccessfully: "Foto excluída com sucesso.",
    deletePhotoPrompt: "Tem certeza de que deseja excluir a foto?",
    invalidPin:
      "Identificador inválido. Você não tem permissão para excluir esta foto.",
    startSlideshowError: "Não há fotos para exibir.",
    performDeleteError: "Falha ao excluir a foto.",
    confirmPinForSpecialDelete:
      "Identificamos que você não enviou esta foto. Para excluí-la, insira o PIN secreto:",
    invalidPinMessage:
      "Identificador inválido. Você não tem permissão para excluir esta foto.",
    stopSlideshow: "Parar apresentação",
    pauseSlideshow: "Pausar apresentação",
    resumeSlideshow: "Retomar apresentação",
    nextSlide: "Próximo slide",
    prevSlide: "Slide anterior",
    slideshowPaused: "A apresentação foi pausada",
    slideshowResumed: "A apresentação foi retomada",
    loading: "Carregando...",
    handlePhotoMessageChange: "A mensagem não deve ter mais de 8 palavras.",
    albumLink: "Link do álbum",
    closeBanner: "Fechar banner",
    copyLink: "Copiar link",
    shareImage: "Compartilhar imagem",
    imageShared: "Imagem compartilhada!",
    imageSharedTitle: "¡Mirá esta imagen!",
    copyLinkSuccess: "Link copiado!",
    
    reminderBannerMessage:
      "Para acessar facilmente o álbum mais tarde sem o QR, <strong>copie o link abaixo</strong> e guarde-o nas suas anotações ou compartilhe com seu grupo do WhatsApp.",
    reminderBannerButton: "Copiar Link",
    fileUploadMultipleFilesMessage:
      "Você pode fazer upload de fotos e vídeos para este evento.",
    fileUploadButton: "Clique para enviar sua foto",
    loadingMessage: "Sua foto está sendo carregada...",
    eventTitle: "Nos divertimos assim!",
    photographerPhotosButton: "Ícone para fotógrafos",
    guestBookMessagePlaceholder: "Escreva sua mensagem no livro de visitas...",
    submitGuestBookButton: "Enviar Mensagem",
    download: "Baixar",
    share: "Compartilhar",
    delete: "Excluir",

    // Guestbook
    firstMessage: "Seja o primeiro a escrever algo!",
    loadingMessages: "Carregando mensagens...",
    guestbookTitle: "Deixe seu mensagem",
    messageTimestamp: "Há pouco",

    // Photo overlay

    uploaderInfo: (name, message) => `${name} ${message ? `- ${message}` : ""}`,
    slideshowButton: "Botão de apresentação de slides",
    startSlideshow: "Iniciar apresentação",
    seeAllPhotos: "Ver todas as fotos (autoshow)",
    reloadPhotos: "Recarregar fotos",
    slideshowModal: "Modal de apresentação de slides",
    previous: "Anterior",
    next: "Próximo",

    closeSlideshow: "Fechar apresentação",

    scrollToTop: "Botão para voltar ao topo",
    paginationControls: "Controles de paginação",
    renderPhotos: "Renderizar fotos",
    footer: "Rodapé",
    termsAndConditions: "Termos e Condições",
  },
  es: {
    //form to send photo
    inputNameSendPhoto:"Tu nombre (requerido)",
    helmetTitle:"Álbum para Eventos con Código QR",
    guestBookSentMessageError:
      "Hubo un error al enviar tu mensaje. Intenta nuevamente.",
    title: "Álbum",
    uploaderName: "Nombre del cargador",
    albumOf: "Álbum de",
    coverImageUrl: "URL de la imagen de portada",
    customMessage: "Mensaje personalizado",
    photoMessage: "Mensaje de la foto",
    errorMessage: "Error al cargar el álbum. Intenta nuevamente.",
    slideshowActive: "Presentación de diapositivas activa",
    nextPhoto: "Foto siguiente",
    prevPhoto: "Foto anterior",
    guestBookActive: "Libro de mensajes activo",
    openGuestBook: "Abrir libro de mensajes",
    submitMessage: "Enviar mensaje",
    guestBookMessage: "Mensaje en el libro de mensajes",
    loadingEventDetails: "Cargando detalles del evento...",
    pageTitle: "Página del Álbum",
    bannerClosed: "El banner fue cerrado.",
    openModal: "Abrir modal",
    closeModal: "Cerrar modal",
    userIdGenerated: "Nuevo ID de usuario generado",
    fetchingMessages: "Buscando mensajes...",
    noMessagesFound: "No se encontraron mensajes.",
    errorFetchingMessages: "Error al buscar mensajes.",
    photoDownloadSuccess: "¡Foto descargada con éxito!",
    photoDownloadError: "Error al descargar la foto.",
    fetchingEventDetails: "Buscando detalles del evento...",
    eventNotFound: "Evento no encontrado.",
    likeSuccess: "¡Me gusta realizado con éxito!",
    
    // Heart
    likeError: "Error al dar me gusta en la foto.",
    likeHeart: "me gusta",

    secondaryThemeApplied: "Tema secundario aplicado.",
    secondaryThemeRemoved: "Tema secundario eliminado.",
    handleDeleteConfirmation:
      "¿Estás seguro de que querés borrar este mensaje?",
    deleteMessageError: "Error al borrar el mensaje:",
    fetchingPhotosError: "Error al obtener las fotos:",
    photoUploadError: "Error subiendo el archivo:",
    uploadInProgress: "Subida en progreso, por favor espera...",
    noFileSelected: "Por favor, seleccioná al menos un archivo para subir.",
    noNameEntered: "Por favor, ingresá tu nombre antes de subir la foto.",
    safeSearchEvaluationMessage:
      "La foto está en proceso de evaluación debido a posibles contenidos inapropiados.",
    photoDeletedSuccessfully: "Foto borrada con éxito.",
    deletePhotoPrompt: "¿Estás seguro que querés borrar la foto?",
    invalidPin:
      "Identificador inválido. No tenés permiso para borrar esta foto.",
    startSlideshowError: "No hay fotos para mostrar.",
    performDeleteError: "Falla al borrar la foto.",
    confirmPinForSpecialDelete:
      "Identificamos que vos no subiste esta foto. Para borrarla, ingresá el PIN secreto:",
    invalidPinMessage:
      "Identificador inválido. No tenés permiso para borrar esta foto.",
    stopSlideshow: "Detener presentación",
    pauseSlideshow: "Pausar presentación",
    resumeSlideshow: "Reanudar presentación",
    nextSlide: "Siguiente diapositiva",
    prevSlide: "Diapositiva anterior",
    slideshowPaused: "La presentación ha sido pausada",
    slideshowResumed: "La presentación ha sido reanudada",
    loading: "Cargando...",
    handlePhotoMessageChange: "El mensaje no debe tener más de 8 palabras.",
    albumLink: "Enlace del álbum",
    closeBanner: "Cerrar banner",
    copyLink: "Copiar enlace",
    shareImage: "Compartir imagen",
    imageShared: "¡Imagen compartida!",
    imageSharedTitle: "Veja esta imagem!",
    copyLinkSuccess: "¡Enlace copiado!",

    reminderBannerMessage:
      "Para acceder fácilmente al álbum más tarde sin el QR, <strong>copiá el enlace de abajo</strong> y guardalo entre tus notas o compartilo con tu grupo de WhatsApp.",
    reminderBannerButton: "Copiar Enlace",
    fileUploadMultipleFilesMessage:
      "Puedes subir tanto fotos como videos para este evento.",
    fileUploadButton: "Hacé clic para subir tu foto",
    loadingMessage: "Tu foto se está subiendo...",
    eventTitle: "¡Así la Pasamos!",
    photographerPhotosButton: "Ícono para fotógrafos",
    guestBookMessagePlaceholder: "Escribe tu mensaje en el libro de visitas...",
    submitGuestBookButton: "Enviar Mensaje",
    download: "Descargá",
    share: "Compartí",
    delete: "Borrá",

    // Guestbook
    firstMessage: "¡Podés ser el primero en escribir algo!",
    loadingMessages: "Cargando mensajes...",
    guestbookTitle: "Dejá tu mensaje",
    messageTimestamp: "Hace poco",

    // Photo overlay

    uploaderInfo: (name, message) => `${name} ${message ? `- ${message}` : ""}`,
    slideshowButton: "Botón de presentación de diapositivas",
    startSlideshow: "Iniciar presentación",
    seeAllPhotos: "Ver todas las fotos (autoshow)",
    reloadPhotos: "Recargar fotos",
    slideshowModal: "Modal de presentación de diapositivas",
    previous: "Anterior",
    next: "Siguiente",
    closeSlideshow: "Cerrar presentación",
    scrollToTop: "Botón para subir",
    paginationControls: "Controles de paginación",
    renderPhotos: "Renderizar fotos",
    footer: "Pie de página",
    termsAndConditions: "Términos y Condiciones",
  },
  en: {
    // Input name send photo form
    inputNameSendPhoto: "Your name (required)",
    helmetTitle:"QR Code Photo Album",
    guestBookSentMessageError:
      "There was an error sending your message. Please try again.",
    title: "Album",
    uploaderName: "Uploader's name",
    albumOf: "Album of",
    coverImageUrl: "Cover image URL",
    customMessage: "Custom message",
    photoMessage: "Photo message",
    errorMessage: "Error loading album. Please try again.",
    slideshowActive: "Slideshow active",
    nextPhoto: "Next photo",
    prevPhoto: "Previous photo",
    guestBookActive: "Guestbook active",
    openGuestBook: "Open guestbook",
    submitMessage: "Submit message",
    guestBookMessage: "Message in guestbook",
    loadingEventDetails: "Loading event details...",
    pageTitle: "Album Page",
    bannerClosed: "The banner has been closed.",
    openModal: "Open modal",
    closeModal: "Close modal",
    userIdGenerated: "New user ID generated",
    fetchingMessages: "Fetching messages...",
    noMessagesFound: "No messages found.",
    errorFetchingMessages: "Error fetching messages.",
    photoDownloadSuccess: "Photo downloaded successfully!",
    photoDownloadError: "Error downloading photo.",
    fetchingEventDetails: "Fetching event details...",
    eventNotFound: "Event not found.",
    likeSuccess: "Like completed successfully!",
  
    likeError: "Error liking the photo.",
    likeHeart: "like",
  
    secondaryThemeApplied: "Secondary theme applied.",
    secondaryThemeRemoved: "Secondary theme removed.",
    handleDeleteConfirmation:
      "Are you sure you want to delete this message?",
    deleteMessageError: "Error deleting message:",
    fetchingPhotosError: "Error fetching photos:",
    photoUploadError: "Error uploading file:",
    uploadInProgress: "Upload in progress, please wait...",
    noFileSelected: "Please select at least one file to upload.",
    noNameEntered: "Please enter your name before uploading the photo.",
    safeSearchEvaluationMessage:
      "The photo is being evaluated for possible inappropriate content.",
    photoDeletedSuccessfully: "Photo deleted successfully.",
    deletePhotoPrompt: "Are you sure you want to delete the photo?",
    invalidPin:
      "Invalid identifier. You do not have permission to delete this photo.",
    startSlideshowError: "No photos to display.",
    performDeleteError: "Failed to delete the photo.",
    confirmPinForSpecialDelete:
      "We identified that you did not upload this photo. To delete it, enter the secret PIN:",
    invalidPinMessage:
      "Invalid identifier. You do not have permission to delete this photo.",
    stopSlideshow: "Stop slideshow",
    pauseSlideshow: "Pause slideshow",
    resumeSlideshow: "Resume slideshow",
    nextSlide: "Next slide",
    prevSlide: "Previous slide",
    slideshowPaused: "Slideshow paused",
    slideshowResumed: "Slideshow resumed",
    loading: "Loading...",
    handlePhotoMessageChange: "The message should not exceed 8 words.",
    albumLink: "Album link",
    closeBanner: "Close banner",
    copyLink: "Copy link",
    shareImage: "Share image",
    imageShared: "Image shared!",
    imageSharedTitle: "Check out this image!",
    copyLinkSuccess: "Link copied!",
  
    reminderBannerMessage:
      "To easily access the album later without the QR, <strong>copy the link below</strong> and save it in your notes or share it with your WhatsApp group.",
    reminderBannerButton: "Copy Link",
    fileUploadMultipleFilesMessage:
      "You can upload photos and videos for this event.",
    fileUploadButton: "Click to upload your photo",
    loadingMessage: "Your photo is uploading...",
    eventTitle: "This is how we party!",
    photographerPhotosButton: "Icon for photographers",
    guestBookMessagePlaceholder: "Write your message in the guestbook...",
    submitGuestBookButton: "Submit message",
    download: "Download",
    share: "Share",
    delete: "Delete",
  
    // Guestbook
    firstMessage: "Be the first to write something!",
    loadingMessages: "Loading messages...",
    guestbookTitle: "Leave your message",
    messageTimestamp: "Just now",
  
    // Photo overlay
    uploaderInfo: (name, message) => `${name} ${message ? `- ${message}` : ""}`,
    slideshowButton: "Slideshow button",
    startSlideshow: "Start slideshow",
    seeAllPhotos: "See all photos (auto-show)",
    reloadPhotos: "Reload photos",
    slideshowModal: "Slideshow modal",
    previous: "Previous",
    next: "Next",
    closeSlideshow: "Close slideshow",
    scrollToTop: "Button to scroll to top",
    paginationControls: "Pagination controls",
    renderPhotos: "Render photos",
    footer: "Footer",
    termsAndConditions: "Terms and Conditions",
  }
};
