
// import React from 'react';
// import { Link } from 'react-router-dom'; // Import Link from react-router-dom
// import "./Footer.css"

// const Footer = () => (
//     <footer className="Album-footer">
//     <p>Email: hola@snapmoments.com<br/>&copy; {new Date().getFullYear()} SnapMoments. Todos los derechos reservados.</p>   
//   </footer>
// );

// export default Footer;
import React from 'react';
import "./Footer.css";

function Footer () {
  const lang = navigator.language.split("-")[0]; // Get the language code (es or pt)

  const footerContent  = {
    pt: {
      footerRightAds: 'Quer um álbum do Snap Moments na sua festa? Clique <a href="https://www.instagram.com/snapmoments_br/" target="_blank" rel="noopener noreferrer">aqui</a> para mais informações!'
    },
    es: {
      footerRightAds: '¿Querés un álbum de Snap Moments en tu fiesta? ¡Clicá <a href="https://www.instagram.com/snapmomentsok/" target="_blank" rel="noopener noreferrer">acá</a> para más info!'
    },
    en: {
      footerRightAds: 'Want a Snap Moments album at your party? Click <a href="https://www.instagram.com/snapmoments_us/" target="_blank" rel="noopener noreferrer">here</a> for more information!'
    }
  };
  
  // const content = lang === "pt" ? templateContent.pt : templateContent.es;
  const content = footerContent[lang] 
  ? footerContent[lang] 
  : footerContent['en']; // Fallback to 'en'


  return(


  <footer className="Album-footer">
    <div className="footer-content">
      <div className="footer-social">
        <a href="https://www.instagram.com/snapmomentsok/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram instagram-icon"></i> {/* FontAwesome Instagram icon */}
        </a>
        <a href="mailto:contacto@snap-moments.com" className="footer-email">
          <i className="fas fa-envelope email-icon"></i> {/* FontAwesome email icon */}
        </a>
      </div>
      <p className="footer-rights-ad"
      dangerouslySetInnerHTML={{
        __html: content.footerRightAds,
      }}
      ></p>
      <p className="footer-rights">&copy; {new Date().getFullYear()} SnapMoments.</p>
      {/* <p className="footer-rights">&copy; {new Date().getFullYear()} SnapMoments. Todos los derechos reservados.</p> */}
    </div>
  </footer>
)};

export default Footer;
