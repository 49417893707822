import React from 'react';

const PhotoMessageInput = ({ value, onChange, errorMessage }) => {

  const photoMessageInputTranlations = {
    pt: {
      textAreaPlaceholder: "Escreva uma mensagem de até 8 palavras (opcional)."
    },
    es: {
      textAreaPlaceholder: "Escribí un mensaje de hasta 8 palabras (opcional)."
    },
    en: {
      textAreaPlaceholder: "Write a message of up to 8 words (optional)."
    }
  };

  const lang = navigator.language.split("-")[0]; // Get the language code (e.g., 'es', 'pt')

  const content = photoMessageInputTranlations[lang]
    ? photoMessageInputTranlations[lang]
    : photoMessageInputTranlations["en"]; // Fallback to 'en'

  return (
    <div className="photo-message-container">
      <textarea
        className="photo-message"
        value={value}
        placeholder={content.textAreaPlaceholder}
        onChange={onChange}
        rows="1"
      />
      
    </div>
  );
};

export default PhotoMessageInput;
