import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import axios from "axios";
import { API_GET_EVENT_LIST } from "./endpoints";
import "./App.css";
import Header from "./Components/Header/Header";
import "./assets/styles/ListEvents.css";

const ListEvents = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  const [hiddenEvents, setHiddenEvents] = useState([]); // State to track hidden events
  const [sortOrder, setSortOrder] = useState('asc'); // Default to ascending


  useEffect(() => {
    // Load hidden events from localStorage
    const savedHiddenEvents = JSON.parse(localStorage.getItem('hiddenEvents')) || [];
    setHiddenEvents(savedHiddenEvents);
    
    const fetchEvents = async () => {
      try {
        // const response = await axios.get(API_GET_EVENT_LIST);
        const response = await axios.get(`${API_GET_EVENT_LIST}?sortOrder=${sortOrder}`);

        
        
        setEvents(response.data); // Ensure response.data is an array

      } catch (error) {
        setError("Error fetching events");
        console.error(error);
      }
    };

    fetchEvents();
  }, [sortOrder]);

  // Toggle sorting order
const toggleSortOrder = () => {
  setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
};

  const formatFirestoreDate = (timestamp) => {
    if (timestamp && timestamp._seconds) {
      return new Date(timestamp._seconds * 1000).toLocaleString();
    }
    return "N/A";
  };

  // Handle hiding an event
  const toggleEventVisibility = (eventId) => {
    const updatedHiddenEvents = hiddenEvents.includes(eventId)
      ? hiddenEvents.filter((id) => id !== eventId) // Remove from hidden list
      : [...hiddenEvents, eventId]; // Add to hidden list

    setHiddenEvents(updatedHiddenEvents);
    // Save updated list of hidden events to localStorage
    localStorage.setItem('hiddenEvents', JSON.stringify(updatedHiddenEvents));
  };
  // Show all hidden events again
  const showAllEvents = () => {
    setHiddenEvents([]); // Clear the hidden events list
    localStorage.removeItem('hiddenEvents'); // Remove from localStorage
  };

  return (
    <div className="create-event-container">
       <Link to="/">
        <Header />
      </Link>
   <div className="list-events-container">
      <h2>Lista de eventos existentes</h2>
      
      <div className="table-container">
      <button onClick={toggleSortOrder}>
  Ordenar por fecha ({sortOrder === 'asc' ? 'Ascendente' : 'Descendente'})
</button>
        <table className="table">
          <thead>
            <tr>
            <th>Acción</th> {/* Add a column for toggling visibility */}
              <th>Album</th>
              <th>ID del evento</th>
              <th>Álbum</th>
              <th>Fecha evento</th>
              <th>Creado por</th>
              <th>Fecha creación</th>
              <th>URL portada</th>
            </tr>
          </thead>
          <tbody>
            {events
                            .filter((event) => !hiddenEvents.includes(event.eventId)) // Only show events not hidden

            .map((event, index) => (
              <tr key={index}>
              <td>
              <button
                        onClick={() => toggleEventVisibility(event.eventId)} // Toggle hide/show
                        className="toggle-visibility-btn"
                      >
                    <i
    onClick={() => toggleEventVisibility(event.eventId)} // Toggle hide/show
    className={`fas ${hiddenEvents.includes(event.eventId) ? 'fa-eye' : 'fa-eye-slash'} toggle-visibility-icon`} // Conditional icon
    aria-hidden="true" // Accessibility attribute
  ></i>
                        {/* {hiddenEvents.includes(event.eventId) ? "Mostrar" : "Ocultar"} */}
                      </button>
              </td>
                <td>
                  <a
                    className="table-link"
                    href={`https://snap-moments.com/album?eventId=${event.eventId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver Album
                  </a>
                </td>
                <td>{event.eventId}</td>
                <td>{event.albumOf}</td>
                <td>{event.eventDate}</td>
                <td>{event.albumCreatedBy}</td>
                <td>{formatFirestoreDate(event.createdAt)}</td>
                <td>
                  <a
                    className="table-link"
                    href={event.coverImageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver Imagen de Portada
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {error && <p className="error-message">{error}</p>}

      {/* Show All button */}
      {hiddenEvents.length > 0 && (
          <button className="show-all-btn" onClick={showAllEvents}>
            Mostrar todos
          </button>
        )}
    </div>
    <footer className="support-footer">
        <p>
          Si experimentás algún inconveniente durante la creación del evento, escribinos a{' '}
          <a href="mailto:contacto@snap-moments.com">contacto@snap-moments.com</a>.
        </p>
      </footer>
    </div>
  );
};

export default ListEvents;


