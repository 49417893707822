export const faqContent = {
  es: {
    title: "Preguntas Frecuentes",
    questions: [
      {
        question: "¿Cómo funciona Snap Moments?",
        answer: `Snap Moments es un álbum digital pensado para simplificar la
        recopilación y captura de fotos de tu evento. Los invitados pueden
        compartir su contenido escaneando un código QR, sin apps ni
        registros. ¡Así, ellos disfrutan y vos tenés todos los recuerdos en
        un solo lugar!`,
      },
      {
        question: "¿Cómo pueden los invitados subir fotos a mi álbum digital?",
        answer: `Hay dos formas de subir contenido: escaneando el código QR o
        accediendo a través del enlace personalizado (URL). Desde allí, los
        invitados pueden optar por cargar fotos desde su teléfono o usar
        la cámara de su dispositivo para tomar fotos en el momento y
        subirlas.`,
      },
      {
        question: "¿Hay un límite de fotos que puedo subir?",
        answer: "No, no hay límite. ¡Podés subir todas las fotos que quieras!",
      },
      {
        question: "¿Puedo descargar las fotos del álbum?",
        answer: `Sí, solo tenés que hacer clic en la foto que deseás y seleccionar el
        ícono de descarga. Únicamente el dueño de la fiesta puede descargar
        el álbum completo (casi) con un sólo clic.`,
      },
      {
        question: "¿Qué hago si subí una foto por error?",
        answer: `Si subiste una foto por error, podés eliminarla fácilmente desde el
        álbum con un clic en el botón de 'Borrar'. 
        Si no sos vos quien subió la foto, vas a necesitar un PIN que
        es entregado al dueño del evento.`,
      },
      {
        question: "¿Cuánto tiempo estará disponible mi álbum online?",
        answer: `¡Tu álbum estará disponible hasta por 3 meses desde la fecha de su
        creación para que sigas reviviendo esos momentos inolvidables!`,
      },
      {
        question: "¿Puedo proyectar las fotos en el evento en vivo?",
        answer: "¡Sí! Usá la función de reproducción automática para proyectar las fotos del álbum durante el evento.",
      },
      {
        question: "¿Tienen control parental para filtrar contenido inapropiado?",
        answer: `¡Sí! contamos con control parental respaldado por la tecnología de
        IA de Google, que detecta y bloquea automáticamente fotos subidas de
        tono.`,
      },
    ],
  },
  pt: {
    title: "Perguntas Frequentes",
    questions: [
      {
        question: "Como funciona o Snap Moments?",
        answer: `Snap Moments é um álbum digital pensado para simplificar a
        coleta e captura de fotos do seu evento. Os convidados podem
        compartilhar seu conteúdo escaneando o código QR, sem precisar de
        aplicativos ou registros. Assim, eles aproveitam e você tem todas as
        memórias em um só lugar!`,
      },
      {
        question: "Como os convidados podem enviar fotos para o meu álbum digital?",
        answer: `Existem duas formas de enviar conteúdo: escaneando o código QR ou
        acessando o link personalizado (URL). De lá, os convidados podem
        escolher enviar fotos do celular ou usar a câmera para tirar fotos
        no momento e carregá-las.`,
      },
      {
        question: "Há um limite de fotos que posso enviar?",
        answer: "Não, não há limite. Você pode enviar quantas fotos quiser!",
      },
      {
        question: "Posso baixar as fotos do álbum?",
        answer: `Sim, basta clicar na foto desejada e selecionar o ícone de download.
        Apenas o dono da festa pode baixar o álbum completo (quase) com um
        clique.`,
      },
      {
        question: "O que faço se enviei uma foto por engano?",
        answer: `Se você enviou uma foto por engano, pode removê-la facilmente no
        álbum clicando no botão de 'Excluir'. 
        Se não foi você quem enviou a foto, será necessário um PIN que
        será fornecido ao dono do evento.`,
      },
      {
        question: "Quanto tempo meu álbum ficará disponível online?",
        answer: `Seu álbum ficará disponível por até 3 meses a partir da data de
        criação para que você continue revivendo esses momentos inesquecíveis!`,
      },
      {
        question: "Posso projetar as fotos no evento ao vivo?",
        answer: "Sim! Use a função de reprodução automática para projetar as fotos do álbum durante o evento.",
      },
      {
        question: "Há controle parental para filtrar conteúdo impróprio?",
        answer: `Sim! Temos controle parental baseado na tecnologia de IA do Google,
        que detecta e bloqueia automaticamente fotos impróprias.`,
      },
    ],
  },
  en: {
    title: "Frequently Asked Questions",
    questions: [
      {
        question: "How does Snap Moments work?",
        answer: `Snap Moments is a digital album designed to simplify the
        collection and capture of photos from your event. Guests can
        share their content by scanning a QR code, without apps or
        registrations. This way, they enjoy the event while you have
        all the memories in one place!`,
      },
      {
        question: "How can guests upload photos to my digital album?",
        answer: `There are two ways to upload content: by scanning the QR code or
        accessing it through the personalized link (URL). From there,
        guests can either upload photos from their phones or use
        their device's camera to take photos on the spot and upload them.`,
      },
      {
        question: "Is there a limit to the number of photos I can upload?",
        answer: "No, there’s no limit. You can upload as many photos as you want!",
      },
      {
        question: "Can I download the photos from the album?",
        answer: `Yes, just click on the photo you want and select the download
        icon. Only the event host can download the entire album
        (almost) with a single click.`,
      },
      {
        question: "What should I do if I uploaded a photo by mistake?",
        answer: `If you uploaded a photo by mistake, you can easily delete it
        from the album by clicking the 'Delete' button.
        If you didn’t upload the photo, you’ll need a PIN, which
        is provided to the event host.`,
      },
      {
        question: "How long will my album be available online?",
        answer: `Your album will be available for up to 3 months from the creation
        date so you can keep reliving those unforgettable moments!`,
      },
      {
        question: "Can I project the photos live during the event?",
        answer: "Yes! Use the autoplay feature to display the album photos during the event.",
      },
      {
        question: "Is there parental control to filter inappropriate content?",
        answer: `Yes! We have parental control powered by Google’s AI technology,
        which automatically detects and blocks inappropriate photos.`,
      },
    ],
  },
};