export const termsAndConditions = {
  pt: {
    title: "Termos e Condições",
    content: "Ao utilizar este serviço, você garante que possui todos os direitos necessários sobre o conteúdo publicado e nos autoriza a utilizá-lo de acordo com estes termos.",
    dataUsage: {
      title: "Uso de Dados:",
      content: "A empresa se compromete a não utilizar as fotos fornecidas para divulgação. Não nos responsabilizamos pelo mau uso que os usuários possam fazer do conteúdo. Ao compartilhar fotos neste serviço, você reconhece que elas são de domínio público e aceita que qualquer uso que os usuários fizerem desses dados é de responsabilidade deles."
    },
    contentRemovalRight: "Reservamo-nos o direito de excluir qualquer conteúdo que infrinja estes termos."
  },
  es: {
    title: "Términos y Condiciones",
    content: "Al utilizar este servicio, usted garantiza que posee todos los derechos necesarios sobre el contenido publicado y nos autoriza a utilizarlo de acuerdo con estos términos.",
    dataUsage: {
      title: "Uso de Datos:",
      content: "La empresa se compromete a no utilizar las fotos proporcionadas para difusión. No nos hacemos responsables del mal uso que los usuarios puedan hacer del contenido. Al compartir fotos en este servicio, usted reconoce que son de dominio público y acepta que cualquier uso que hagan los usuarios de estos datos es responsabilidad de ellos mismos."
    },
    contentRemovalRight: "Nos reservamos el derecho de eliminar cualquier contenido que infrinja estas condiciones."
  },
  en: {
    title: "Terms and Conditions",
    content: "By using this service, you guarantee that you hold all necessary rights to the published content and authorize us to use it in accordance with these terms.",
    dataUsage: {
      title: "Data Usage:",
      content: "The company commits not to use the provided photos for promotion. We are not responsible for any misuse of the content by users. By sharing photos on this service, you acknowledge they are public domain and accept that any use of this data by users is their responsibility."
    },
    contentRemovalRight: "We reserve the right to remove any content that violates these terms."
  }
};
