import { React } from "react";
import { Link } from "react-router-dom";
// import { CARTELES_CANVA, CARTELES_CANVA_2, INVITACION_CUADRADO, INVITACION_VERTICAL, POCKET_QR } from "./CanvaEndpoints";
import "./assets/styles/TemplatePage.css";
import logoEs from "./assets/images/logoSnapOk.png";
import logoPt from "./assets/images/logoSnapPT-bg.png"; // Portuguese logo
import logoEn from "./assets/images/logoSnapEN-bg.png"; // English logo

import templateImage1 from "./assets/images/invitaciones.png";
import templateImage2 from "./assets/images/carteles.png";
import templateImage3 from "./assets/images/pocket.png";
import { Helmet } from "react-helmet-async";
import "./assets/styles/LandingPage.css";
import FooterInstitucional from "./Components/FooterInstitucional/FooterInstitucional";
import { templateContent } from "./translationsTemplate";


function TemplatePage() {
  // Set language to Spanish or Portuguese based on navigator
  const lang = navigator.language.split("-")[0]; // Get the language code (es or pt)

  // Define content for Spanish and Portuguese
  

  // Select content based on the current language
  const content = templateContent[lang] 
  ? templateContent[lang] 
  : templateContent['en']; // Fallback to 'en'
  
  // const logo = lang === "pt" ? logoPt : logoEs;

  let logo;
if (lang === "pt") {
  logo = logoPt;
} else if (lang === "es") {
  logo = logoEs;
} else {
  logo = logoEn; // Default to English logo if no 'pt' or 'es'
}

  return (
    <div className="TemplatePage">
      <Helmet>
        <title>{content.title}</title>
      </Helmet>
      <header className="TemplatePage-header">
        <Link to="/">
          <img src={logo} alt="Logo" className="LandingPage-logo" />
        </Link>
      </header>

      <div className="TemplatePage-content">
        <h2>{content.sectionTitle}</h2>
        <section className="TemplatePage-section">
          <p>{content.sectionDescription}</p>
        </section>

        <h2>{content.modelsTitle}</h2>

        <section className="TemplatePage-section template-gallery">
          <div className="template-item">
            <img
              src={templateImage2}
              alt="Plantilla 2"
              className="template-image"
            />
            <h3>{content.model1.name}</h3>
            <p>{content.model1.description}</p>
            {/* {content.model1.links.map((link, index) => (
              <div key={index} className="model-canvas">
                <a
                  href={link.url}
                  target="_blank"
                  className="download-link"
                  rel="noopener noreferrer"
                >
                  {link.label}
                </a>
              </div>
            ))} */}
          </div>

          <div className="template-item">
            <img
              src={templateImage1}
              alt="Plantilla 1"
              className="template-image"
            />
            <h3>{content.model2.name}</h3>
            <p>{content.model2.description}</p>
            {/* {content.model2.links.map((link, index) => (
              <div key={index} className="model-canvas">
                <a
                  href={link.url}
                  target="_blank"
                  className="download-link"
                  rel="noopener noreferrer"
                >
                  {link.label}
                </a>
              </div>
            ))} */}
          </div>

          <div className="template-item">
            <img
              src={templateImage3}
              alt="Plantilla 3"
              className="template-image"
            />
            <h3>{content.model3.name}</h3>
            <p>{content.model3.description}</p>
            {/* {content.model3.links.map((link, index) => (
              <div key={index} className="model-canvas">
                <a
                  href={link.url}
                  target="_blank"
                  className="download-link"
                  rel="noopener noreferrer"
                >
                  {link.label}
                </a>
              </div>
            ))} */}
          </div>
        </section>

        <h2>{content.funWaysTitle}</h2>

        <section className="TemplatePage-section template-gallery">
  {content.useCases.map((useCase, index) => (
    <div key={index} className={`use-cases ${useCase.className}`}>
      <h3>{useCase.title}</h3>
      <p>{useCase.description}</p>
    </div>
  ))}
</section>
      </div>

      <FooterInstitucional />
    </div>
  );
}

export default TemplatePage;
