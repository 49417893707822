import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_DOWNLOAD_ALBUM_URL, API_DOWNLOAD_GUESTBOOK_URL } from "./endpoints";
import axios from "axios";
import Header from "./Components/Header/Header";
import "./assets/styles/DownloadAlbum.css";
import { Helmet } from "react-helmet";

//backlog palabra pase

const DownloadAlbum = () => {
  const [eventId, setEventId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDownloading, setIsDownloading] = useState(false); // New state to handle loading
  const [downloadComplete, setDownloadComplete] = useState(false); // New state for download success
  const [isDownloadingGuestbook, setIsDownloadingGuestbook] = useState(false);

  const translationsDownloadAlbum = {
    pt: {
      title: "Baixe seu álbum",
      subHeading: "Digite o ID do seu evento:",
      subtitle: "O ID do evento está no final do link do seu álbum, logo após <strong>\"eventId=\"</strong>. Por exemplo, no link <strong>snap-moments.com/album?eventId=minha-festa</strong>, o ID do evento é \"minha-festa\".",
      placeholder: "ID do evento",
      emptyAlbumId: "Por favor, insira um ID do evento.",
      downloadAlbum: "Baixar álbum",
      downloadGuestbook: "Baixar livro de mensagens",
      processing: "Processando...",
      downloadMessage: "O tempo de download pode variar de acordo com o tamanho do álbum. Por favor, aguarde alguns minutos...",
      errorMessage: "Houve um erro ao baixar o álbum. Tente novamente.",
      errorMessageGuestbook: "Ocorreu um erro ao baixar o livro de mensagens. Por favor, tente novamente.",
      albumNotFound: "Álbum não encontrado",
      successMessage: "O álbum foi baixado com sucesso!",
      recommendation: "<strong>Importante:</strong> Recomendamos fazer o <strong>download de um computador</strong> em vez de um dispositivo móvel para evitar problemas de armazenamento e compatibilidade.<br/><br/>A pasta do álbum será baixada no formato <strong>ZIP</strong> e pode ter um <strong>tamanho considerável</strong>.",
      supportMessage: "Se você tiver problemas ao baixar, entre em contato com a gente em <a href='mailto:contato@snap-moments.com'>contato@snap-moments.com</a>, mencionando o nome do seu álbum."
    },
    es: {
      title: "Descargá tu álbum",
      subHeading:"Ingresá el ID de tu evento:",
      subtitle: "El ID del evento se encuentra al final del enlace de tu álbum, justo después de <strong>\"eventId=\"</strong>. Por ejemplo, en el enlace <strong>snap-moments.com/album?eventId=mi-fiesta</strong>, el ID del evento es \"mi-fiesta\".",
      placeholder: "ID del evento",
      emptyAlbumId:"Por favor, ingresá un ID del evento.",
      downloadAlbum: "Descargar álbum",
      downloadGuestbook: "Descargar libro de mensajes",
      processing: "Procesando...",
      downloadMessage: "El tiempo de descarga puede variar según el tamaño del álbum. Por favor, aguardá unos minutos...",
      errorMessage: "Hubo un error al descargar el álbum. Por favor, intentá nuevamente.",
      errorMessageGuestbook:"Hubo un error al descargar el libro de mensajes. Por favor, intentá nuevamente.",
      albumNotFound: "Álbum no encontrado",
      successMessage: "¡El álbum ha sido descargado con éxito!",
      recommendation: "<strong>Importante:</strong> Te sugerimos hacer la <strong>descarga desde una computadora</strong> en lugar de un dispositivo móvil para evitar problemas de almacenamiento y compatibilidad.<br/><br/>La carpeta del álbum se descarga en <strong>formato ZIP</strong> y puede tener un <strong>tamaño considerable</strong>.",
      supportMessage: "Si experimentás algún inconveniente durante la descarga, escribinos a <a href='mailto:contacto@snap-moments.com'>contacto@snap-moments.com</a> mencionando el nombre de tu álbum."
    },
    en: {
      title: "Download your Album",
      subHeading: "Enter your event ID:",
      subtitle: "The event ID is at the end of your album link, right after <strong>\"eventId=\"</strong>. For example, in the link <strong>snap-moments.com/album?eventId=my-party</strong>, the event ID is \"my-party\".",
      placeholder: "Event ID",
      emptyAlbumId: "Please enter an event ID.",
      downloadAlbum: "Download Album",
      downloadGuestbook: "Download Guestbook",
      processing: "Processing...",
      downloadMessage: "The download time may vary depending on the album size. Please wait a few minutes...",
      errorMessage: "There was an error downloading the album. Please try again.",
      errorMessageGuestbook: "There was an error downloading the guestbook. Please try again.",
      albumNotFound: "Album not found",
      successMessage: "The album was downloaded successfully!",
      recommendation: "<strong>Important:</strong> We recommend downloading from a <strong>computer</strong> instead of a mobile device to avoid storage and compatibility issues.<br/><br/>The album folder will be downloaded in <strong>ZIP format</strong> and may have a <strong>considerable size</strong>.",
      supportMessage: "If you encounter issues downloading, contact us at <a href='mailto:contacto@snap-moments.com'>contact@snap-moments.com</a> and mention your album name."
    }
  };

  const lang = navigator.language.split("-")[0]; // Get the language code (es or pt)

  const content = translationsDownloadAlbum[lang] 
  ? translationsDownloadAlbum[lang] 
  : translationsDownloadAlbum['en']; // Fallback to 'en'
  
  // const content = lang === "pt" ? translationsDownloadAlbum.pt : translationsDownloadAlbum.es;


  const handleDownload = async () => {
    if (!eventId) {
      setErrorMessage(content.emptyAlbumId);
      return;
    }

    setIsDownloading(true); // Start loading
    setDownloadComplete(false); // Reset downloadComplete state
    setErrorMessage(""); // Clear previous error messages

    try {
      const response = await axios({
        url: `${API_DOWNLOAD_ALBUM_URL}?eventId=${eventId}`,
        method: "GET",
        responseType: "blob", // important to handle the zip file as binary
      });

      // Create a URL for the file and download it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `album-${eventId}.zip`); // File name for the downloaded zip
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadComplete(true); // Set success message
    } catch (error) {
      console.error("Error downloading album or not found.");
      if (error.response && error.response.status === 404) {
        setErrorMessage(content.albumNotFound);
        console.info("No album found");
      } else {
        setErrorMessage(
          content.errorMessage
        );
      }
    } finally {
      setIsDownloading(false); // Stop loading regardless of success/failure
    }
  };

   // Function to download guestbook
   const handleDownloadGuestbook = async () => {
    if (!eventId) {
      setErrorMessage(content.emptyAlbumId);
      return;
    }

    // setIsDownloading(true);
    setIsDownloadingGuestbook(true);

    setDownloadComplete(false);
    setErrorMessage("");

    try {
      const response = await axios({
        url: API_DOWNLOAD_GUESTBOOK_URL,
        method: "POST",
        data: { eventId },  // Send eventId in the request body
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `guestbook-${eventId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadComplete(true);
    } catch (error) {
      console.error("Error downloading guestbook or not found.");
      setErrorMessage(content.errorMessageGuestbook);
    } finally {
      setIsDownloadingGuestbook(false);
    }
  };

  return (
    <div className="download-album-container">
      <Helmet>
        <title>{content.title}</title>
      </Helmet>
      <Link to="/">
        <Header />
      </Link>
      <h2>{content.title}</h2>
      <p className="title">{content.subHeading}</p>
      <p
  className="subtitle"
  dangerouslySetInnerHTML={{ __html: content.subtitle }}
/>
      
      <input
        type="text"
        value={eventId}
        onChange={(e) => setEventId(e.target.value)}
        placeholder={content.placeholder}
        required
      />




      <button onClick={handleDownload} disabled={isDownloading}>
      {isDownloading ? content.processing : content.downloadAlbum}
      </button>


      <button onClick={handleDownloadGuestbook} className="download-guestbook" disabled={isDownloadingGuestbook}>
        {isDownloadingGuestbook  ? content.processing : content.downloadGuestbook}
      </button>

      {isDownloading && (
        <p>
          {content.downloadMessage}
        </p>
      )}{" "}
      {/* Show this while downloading */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {downloadComplete && (
        <p className="success-message">
          {content.successMessage}
        </p>
      )}{" "}
      {/* Show this after successful download */}
      {/* New text about downloading from a computer */}
      <p
  className="recommendation"
  dangerouslySetInnerHTML={{ __html: content.recommendation }}
/>

      {/* Support message */}
      <footer className="support-footer">
      <p

  dangerouslySetInnerHTML={{ __html: content.supportMessage }}
/>

       
      </footer>
    </div>
  );
};

export default DownloadAlbum;
