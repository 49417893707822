// Components/TermsAndConditionsModal.js
import React from "react";
import "./TermsAndConditionsModal.css"; 
import { termsAndConditions } from "./termsTranslations";

const TermsAndConditionsModal = ({ isOpen, onClose }) => {

  const lang = navigator.language.split("-")[0]; // Get the language code (es or pt)

  // const content = lang === "pt" ? termsAndConditions.pt : termsAndConditions.es;

  const content = termsAndConditions[lang] 
  ? termsAndConditions[lang] 
  : termsAndConditions['en']; // Fallback to 'en'

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
        <i className="fas fa-times"></i>
        </button>
        <div
          className="modal-content-inner"
          style={{ background: "white", padding: "20px" }}
        >
          <h2>{content.title}</h2>
          <p>{content.content}</p>
          <p><strong>{content.dataUsage.title}</strong> {content.dataUsage.content}</p>
          <p>{content.contentRemovalRight}</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
